import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import UsersList from '../../components/UsersList';
import { CircularProgress, Snackbar } from '@mui/material';
import { createApiClient } from 'api/apiClient';
import TabTopButton from 'components/TabTopButton';
import {
  CarePlanResponse,
  GetUserListResponse,
  UpdateAlertStatusRequest,
  UpdateUserResponse,
  User,
} from 'api/SharedTypes';
import { FormFieldObject } from 'components/UserForm';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  FormFieldType,
  SubmitFormData,
  UserRole,
} from 'services/Utils';
import { UpdateDialog } from 'components/UpdateDialog';
import { useIntl } from 'react-intl';
import I18nKey from '../../lib/I18nKeys';
import DevicePairing from '../../components/DevicePairing';
import { ExceptionDialog } from '../../components/ExceptionDialog';
import { NavRoutes } from '../NavRouter';
import {
  sortByName,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import isArray from 'lodash/isArray';
import { usePatientData } from './usePatientData';
import { useMutation, useQuery } from 'react-query';
import './style.css';
import { UserContext, UserContextState } from 'contexts/userContext';
import { AutomationDialog } from 'components/AutomationDialog/AutomationDialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

const Patients: React.FC = () => {
  const { isReadOnly, userListingData } =
    useContext<UserContextState>(UserContext);
  const accessToken = localStorage.getItem('access_token');
  const controller = useMemo(() => new AbortController(), []);
  const apiClient = createApiClient(accessToken!, controller);
  const {
    patientUserData,
    patientFormActions,
    patientFormData,
    setPatientFormData,
    getFormSubmitData,
    putUpdateFormData,
    clearForm,
  } = usePatientData();
  const [showSavingStatus, setShowSavingStatus] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [patientId, setPatientId] = useState<number | null>(null);
  const [patientNetwork, setPatientNetwork] = useState<GetUserListResponse[]>(
    [],
  );
  const [carePlans, setCarePlans] = useState<CarePlanResponse[]>([]);
  const { userData } = useContext<UserContextState>(UserContext);

  const [selectedPatientData, setSelectedPatientData] = useState<any>(null);
  const [consentsArray, setConsentsArray] = useState<any>(null);
  const [physical_caregemP, setPhysical_caregemP] = useState<
    string | null | undefined
  >(null);
  const [physical_rpmP, setPhysical_rpmP] = useState<string | null | undefined>(
    null,
  );
  const [digital_cg_sign_date, setDigital_cg_sign_date] = useState<
    boolean | string | number
  >();
  const [digital_rpm_sign_date, setDigital_rpm_sign_date] = useState<
    boolean | string | number
  >();

  const [physicalCaregemConsentP, setPhysicalCaregemConsentP] = useState<
    string | null | boolean | number
  >('1');
  const [physicalRPMConsentP, setPhysicalRPMConsentP] = useState<
    string | null | boolean | number
  >('1');

  const [physicalCaregemConsentC, setPhysicalCaregemConsentC] = useState<
    string | null | boolean | number
  >();
  const [physicalRPMConsentC, setPhysicalRPMConsentC] = useState<
    string | null | boolean | number
  >();

  // const handleConsentChange = (
  //   caregemConsent: string | null,
  //   rpmConsent: string | null,
  // ) => {
  //   // Handle the updated consent values here
  //   // console.log('Physical Caregem Consent:', caregemConsent);
  //   // console.log('Physical RPM Consent:', rpmConsent);
  //   setPhysicalCaregemConsentP(caregemConsent ?? null);
  //   setPhysicalRPMConsentP(rpmConsent ?? null);
  //   setPhysicalCaregemConsentC(caregemConsent ?? null);
  //   setPhysicalRPMConsentC(rpmConsent ?? null);
  // };

  const handleUpdateParent = () => {
    // Handle update logic in parent component
    // console.log('Update in parent component');
    // console.log('physicalCaregemConsent', physicalCaregemConsentP, 'PARENT');
    // console.log('physicalRPMConsent', physicalRPMConsentP, 'PARENT');
  };

  const intl = useIntl();

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [isExceptionDialogOpen, setIsExceptionDialogOpen] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [existingUserData, setExistingUserData] = useState<User>({});
  const [isAutomationDialogOpen, setIsAutomationDialogOpen] =
    useState<boolean>(false);

  const OrgID = userData?.userOrg;
  const OrgId: string =
    OrgID !== undefined ? OrgID.toString() : 'default value';

  const [shouldFetchCarePlans, setShouldFetchCarePlans] = useState(false);

  const {
    data: dataDisableComponent,
    isLoading: isLoadingDisableComponent,
    refetch: refetchDisableComponent,
  } = useQuery(
    'disableComponent',
    async () =>
      createApiClient(accessToken!, controller).getDisableContent(OrgId),
    {
      onSuccess: (data: any) => {
        sessionStorage.setItem('disableComponent', JSON.stringify(data));
        if (data?.VBC_Program === 1) {
          setCarePlans([
            {
              id: 1,
              name: 'None',
            },
          ]);
        } else {
          setCarePlans([
            {
              id: 1,
              name: 'None',
            },
            {
              id: 2,
              name: 'CKCC Program',
            },
          ]);
        }
      },
    },
  );

  useEffect(() => {
    refetchDisableComponent();
  }, [OrgId]);

  // useEffect(() => {
  //   if (!shouldFetchCarePlans) {
  //     refetchCarePlans(); // Refetch the care plans when condition is met
  //   }
  // }, [shouldFetchCarePlans]);

  // const {
  //   data: carePlanData,
  //   isLoading: isLoadingCarePlans,
  //   refetch: refetchCarePlans,
  // } = useQuery(
  //   'care-plans',
  //   async () =>
  //     apiClient.getCarePlans((userData?.userOrg as number).toString()),
  //   {
  //     enabled: false, // Disable automatic query triggering
  //     onSuccess: (data: CarePlanResponse[]) => {
  //       console.log('CarePlanResponse', data);
  //       setCarePlans(data);
  //     },
  //   },
  // );

  const getOrganizationDetails = useQuery(
    'org-detils',
    async () =>
      apiClient.getOrganization_details(
        (userData?.userOrg as number).toString(),
      ),
    {
      onSuccess: (data: any) => {
        sessionStorage.setItem('deviceAssignment', data.device_assignment);
        sessionStorage.setItem('orgName', data.name);
      },
    },
  );

  const {
    isLoading,
    mutate: getPatientList,
    isError,
    data: patientListResp,
  } = useMutation(
    'patient-list',
    async (loadUpdatedData?: boolean, body?: any) =>
      apiClient.getPatientList(loadUpdatedData, body),
    {
      onError: error => {
        // console.log('error ', error);
      },
    },
  );

  // console.log(patientListResp);

  const { isLoading: isLoadingPatientData, mutate: getPatientData } =
    useMutation(
      'get-patient-data',
      async (id: number) => apiClient.getUserData(id, UserRole.patient, true),
      {
        onSuccess: userDataResponse => {
          setPatientFormData(userDataResponse);
          patientFormActions.setPatientData(userDataResponse);
          setShowSavingStatus(false);
        },
        onError: () => {
          handleUpdateDialogClose();
          setShowSavingStatus(false);
        },
      },
    );

  const [showError, setShowError] = useState<any>(false);

  const { isLoading: isUpdatingPatientData, mutate: updatePatientData } =
    useMutation(
      'update-patient-data',
      async (params: { details: SubmitFormData; id: number }) => {
        console.log('Details before posting:', params.details);
        return apiClient.updatePatientData(params.details, params.id);
      },
      {
        onSuccess: (response: any) => {
          if (
            response ===
            'This Email already exits in system, please use a different email'
          ) {
            setShowError(true);
          } else if (response.errorCode) {
            setExceptionDialogData(response);
          } else {
            handleUpdateDialogClose();
            getPatientList(false);
            setIsUpdateDialogOpen(false);
          }
        },
      },
    );

  const {
    isLoading: isNetworkMemberAlertStatusLoading,
    mutate: updateNetworkMemberAlertStatus,
  } = useMutation(
    'update-network-member-alert-status',
    async (params: {
      networkMemberId: number;
      status: UpdateAlertStatusRequest;
      patientId: number;
    }) =>
      apiClient.updatePatientAlertStatus(params.networkMemberId, params.status),
    {
      onSuccess: (_data, params) => {
        getPatientNetworkList(params.patientId);
        setSnackbarMessage('Alert Status updated successfully');
        setShowSnackbar(true);
      },
      onError: () => {
        setShowSavingStatus(false);
        setSnackbarMessage('Failed to update alert status');
        setShowSnackbar(true);
      },
    },
  );

  const {
    isLoading: isPatientNetworkDataLoading,
    mutate: getPatientNetworkList,
  } = useMutation(
    'get-network-list',
    (id: number) => apiClient.getPatientNetworkList(id),
    {
      onSuccess: response => {
        setPatientNetwork(response);
      },
    },
  );

  const patients = useMemo(() => {
    if (patientListResp) {
      if (isArray(patientListResp)) {
        patientListResp[0].forEach(
          (item: { last_name: string; first_name: string }) => {
            item.last_name = item.last_name ? item.last_name : '';
            item.first_name = item.first_name ? item.first_name : '';
          },
        );

        return sortByName(patientListResp);
      }
    }
    return [];
  }, [patientListResp]);

  const handleExceptionDialogClose = () => setIsExceptionDialogOpen(false);

  const handleExceptionDialogOpen = () => setIsExceptionDialogOpen(true);

  const handleSnackbarClose = () => setShowSnackbar(false);

  const handleAutomationDialogClose = () => setIsAutomationDialogOpen(false);

  const handleAutomationAccept = () => {
    patientFormActions.setRemoteMonitoring('I');
    setIsAutomationDialogOpen(false);
  };

  const setExceptionDialogData = (response: UpdateUserResponse) => {
    handleExceptionDialogOpen();
    setErrorCode(response.errorCode!);
    setExistingUserData(response.existing_data!);
  };

  const setExceptionDialogDataError = (response: string) => {
    handleExceptionDialogOpen();
    setErrorCode(response);
  };

  const handleUpdateDialogOpen = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setIsUpdateDialogOpen(false);
    setPatientId(null);
    clearForm();
    patientFormActions.setPatientData(EMPTY_USER_RESPONSE_DATA);
    setShowSavingStatus(false);
    setSnackbarMessage('');
  };

  let patientListing: any = [];

  const onPatientClick = (id: number) => {
    // console.log('onPatientClick', id);
    handleUpdateDialogOpen();
    setPatientId(id);
    getPatientData(id);
    getPatientNetworkList(id);

    const selectedPatient = userListingData.find(
      (patient: any) => patient?.id === id,
    );

    setSelectedPatientData(selectedPatient || '');

    if (selectedPatient) {
      setPhysical_caregemP(selectedPatient.physical_caregem);
      setPhysical_rpmP(selectedPatient.physical_rpm);
      setDigital_cg_sign_date(getSignDate(selectedPatient.consents, 'caregem'));
      setDigital_rpm_sign_date(getSignDate(selectedPatient.consents, 'rpm'));
    }
  };

  function getSignDate(consents, consentName) {
    const consent = consents.find(
      consent => consent.consent_name === consentName,
    );
    // return consent ? true : null;
    // return consent ? consent.sign_date || null : null;
    return consent ? consent.sign_date || null : 'NA';
  }

  const onNetworkMemberAlertStatusUpdate = (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => {
    setShowSavingStatus(true);
    updateNetworkMemberAlertStatus({ networkMemberId, status, patientId });
  };

  const getUserName = () => {
    if (
      patientFormData.firstName &&
      patientFormData.lastName &&
      !patientFormData.userName
    ) {
      apiClient
        .getUserName(patientFormData.firstName, patientFormData.lastName)
        .then(response => patientFormActions.setUserName(response));
    }
  };

  const vbcSelectOptions = carePlans.map((item: CarePlanResponse) => {
    return { value: item.id.toString(), label: item.name };
  });

  const patientFields: FormFieldObject = {
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.firstName,
      onChange: value => patientFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      onBlur: getUserName,
      isError: patientFormData.firstName
        ? !validateName(patientFormData.firstName)
        : false,
      errorText:
        !validateName(patientFormData.firstName) && patientFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    middle_name: {
      type: FormFieldType.text,
      isRequired: false,
      value: patientFormData.middleName,
      onChange: value => patientFormActions.setMiddleName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_MIDDLENAME,
      }),
      isError: patientFormData.middleName
        ? !validateName(patientFormData.middleName)
        : false,
      errorText:
        !validateName(patientFormData.middleName) && patientFormData.middleName
          ? 'Please do not use spaces'
          : '',
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.lastName,
      onChange: value => patientFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      onBlur: getUserName,
      isError: patientFormData.lastName
        ? !validateName(patientFormData.lastName)
        : false,
      errorText:
        !validateName(patientFormData.lastName) && patientFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      isDisabled: true,
      value: patientFormData.userName,
      onChange: value => patientFormActions.setUserName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_USERNAME,
      }),
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      value: patientFormData.email.toLowerCase(),
      onChange: value => patientFormActions.setEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: patientFormData.email
        ? !validateEmail(patientFormData.email)
        : false,
      errorText:
        !validateEmail(patientFormData.email) && patientFormData.email
          ? 'Please enter a valid email'
          : '',
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        patientFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
      hide: true,
    },
    dob: {
      type: FormFieldType.date,
      isRequired: true,
      dateValue: patientFormData.dob,
      onDateChange: value => patientFormActions.setDOB(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DATE_OF_BIRTH,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    ssn: {
      type: FormFieldType.mask,
      isRequired: true,
      value: patientFormData.ssn,
      mask: '####',
      onChange: value => patientFormActions.setSsn(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_SSN,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    gender: {
      type: FormFieldType.select,
      isRequired: true,
      value: patientFormData.gender,
      onChange: value => patientFormActions.setGender(value),
      selectOptions: ExportConstants.genderOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_GENDER,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    home_tel_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: patientFormData.homeTelCountryCode,
      onChange: value => patientFormActions.setHomeTelCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE_COUNTRY_CODE,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    home_tel: {
      type: FormFieldType.mask,
      isRequired: true,
      value: patientFormData.landline,
      onChange: value => patientFormActions.setLandline(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    cell_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: patientFormData.cellCountryCode,
      onChange: value => patientFormActions.setCellCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL_COUNTRY_CODE,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    cell: {
      type: FormFieldType.mask,
      isRequired: true,
      value: patientFormData.mobile,
      onChange: value => patientFormActions.setMobile(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_MOBILE_NUMBER,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    vbc: {
      type: FormFieldType.select,
      isRequired: true,
      value: patientFormData.vbcId,
      selectOptions: vbcSelectOptions,
      onChange: value => patientFormActions.setVbcId(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VBC,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    home_addr_1: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.homeAddr1,
      onChange: value => patientFormActions.setHomeAddr1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_HOME_ADDRESS_1,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    home_addr_2: {
      type: FormFieldType.text,
      isRequired: false,
      value: patientFormData.homeAddr2,
      onChange: value => patientFormActions.setHomeAddr2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_HOME_ADDRESS_2,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    city: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.city,
      onChange: value => patientFormActions.setCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS_CITY,
      }),
      maxLength: 20,
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: patientFormData.state,
      onChange: value => patientFormActions.setState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    zipCode: {
      type: FormFieldType.mask,
      isRequired: true,
      value: patientFormData.zipCode,
      mask: '#########',
      onChange: value => patientFormActions.setZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    licenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: patientFormData.licenseNumber,
      onChange: value => patientFormActions.setLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LICENSE_NUMBER,
      }),
      maxLength: 25,
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    device_paired: {
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEVICE_PAIRED,
      }),
      type: FormFieldType.text,
      value: patientFormData.deviceIMEI
        ? patientFormData.deviceIMEI
        : undefined,
      onChange: () => {},
      isRequired: false,
      isDisabled: true,
    },
    start_date: {
      label: 'Start Date',
      type: FormFieldType.date,
      isRequired: false,
      dateValue: patientFormData.startDate,
      onChange: () => {},
      isDisabled: true,
    },
    refUID: {
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_REF_UID,
      }),
      type: FormFieldType.text,
      value: patientFormData.refUID,
      onChange: () => {},
      isRequired: false,
      isDisabled: true,
    },
  };

  const patientUpdateFields: FormFieldObject = {
    ...patientFields,
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.firstName,
      onChange: value => patientFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      isError: patientFormData.firstName
        ? !validateName(patientFormData.firstName)
        : false,
      errorText:
        !validateName(patientFormData.firstName) && patientFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        patientFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.lastName,
      onChange: value => patientFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      isError: patientFormData.lastName
        ? !validateName(patientFormData.lastName)
        : false,
      errorText:
        !validateName(patientFormData.lastName) && patientFormData.lastName
          ? 'Please do not use spaces'
          : '',
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      value: patientFormData.userName,
      label: intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME }),
      isDisabled: true,
    },
    dob: {
      type: FormFieldType.date,
      isRequired: true,
      dateValue: patientFormData.dob,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DATE_OF_BIRTH,
      }),
      isDisabled: true,
    },
    ssn: {
      type: FormFieldType.mask,
      isRequired: true,
      value: patientFormData.ssn,
      mask: '####',
      onChange: value => patientFormActions.setSsn(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_SSN,
      }),
      isDisabled: true,
    },
    vbc: {
      type: FormFieldType.select,
      isRequired: true,
      value: patientFormData.vbcId,
      selectOptions: vbcSelectOptions,
      onChange: value => {
        patientFormActions.setVbcId(value);
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VBC,
      }),
      isDisabled: !userData?.can_add_patient
        ? isReadOnly
        : !userData?.can_add_patient,
    },
  };

  const fetchPatients = useCallback(
    (loadUpdatedData?: boolean) => getPatientList(loadUpdatedData),
    [getPatientList],
  );

  const updatePatient = async () => {
    const data = putUpdateFormData(patientId);

    if (physicalCaregemConsentC === '1') {
      updatePatientData({
        details: {
          ...data,
          role: UserRole.patient,
          physical_caregem: physicalCaregemConsentC,
        },
        id: patientId!,
      });
    }

    if (physicalRPMConsentC === '1') {
      updatePatientData({
        details: {
          ...data,
          role: UserRole.patient,
          physical_caregem: '1',
          physical_rpm: physicalRPMConsentC,
        },
        id: patientId!,
      });
    }

    // if (physicalRPMConsentC === '1' && physicalRPMConsentC === null) {
    //   updatePatientData({
    //     details: {
    //       ...data,
    //       role: UserRole.patient,
    //       physical_caregem: '1',
    //       physical_rpm: physicalRPMConsentC,
    //     },
    //     id: patientId!,
    //   });
    // }

    updatePatientData({
      details: {
        ...data,
        role: UserRole.patient,
      },
      id: patientId!,
    });
    patientFormActions.setVerifyEmailChecked(false);
  };

  const handleDevicePairingDialog = () => {
    patientFormActions.setDevicePairing(!patientFormData.openDevicePairing);
  };

  const handleRemoteMonitoring = (patientNetwork: GetUserListResponse[]) => {
    if (patientNetwork.length === 0) {
      if (
        window.confirm(
          'To modify remote monitoring you must add a provider to the network!',
        )
      ) {
        return null;
      }
    } else if (sessionStorage.getItem('deviceAssignment') == 'Automated') {
      setIsAutomationDialogOpen(true);
      patientFormActions.setRemoteMonitoring('I');
    } else if (
      sessionStorage.getItem('deviceAssignment') == 'Both' &&
      patientUserData.device_pairing == '' &&
      sessionStorage.getItem('ortderType') == 'auto'
    ) {
      patientFormActions.setRemoteMonitoring('I');
    } else {
      patientFormData.remoteMonitoring === 'Y'
        ? patientFormActions.setRemoteMonitoring('N')
        : patientFormActions.setRemoteMonitoring('Y');
    }
  };

  const reloadUserData = (id: number) => {
    onPatientClick(id ?? '');
  };

  useEffect(() => {
    fetchPatients();
    return () => {
      controller.abort();
    };
  }, [controller, fetchPatients]);

  return (
    <div>
      <Dialog open={showError} onClose={() => setShowError(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          This Email already exits in system, please use a different email
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowError(false);
              handleUpdateDialogClose();
            }}
            color="primary"
            style={{ fontSize: '16px' }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
      <TabTopButton
        role={UserRole.patient}
        getFormSubmitData={getFormSubmitData}
        clearForm={clearForm}
        formFields={patientFields}
        fetchNewDataList={fetchPatients}
      />
      {isLoading ? (
        <div className="patientListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="patientListCenterLoading">
          <div className="red">Failed to Fetch Patients List</div>
        </div>
      ) : (
        <UsersList
          users={patients}
          routes={NavRoutes.patients}
          onUserClick={onPatientClick}
          fetchNewDataList={fetchPatients}
          searchPatientData={getPatientList}
        />
      )}

      <UpdateDialog
        handleClose={handleUpdateDialogClose}
        open={isUpdateDialogOpen}
        currentUserData={patientUserData}
        role={UserRole.patient}
        handleUpdate={updatePatient}
        formFields={patientUpdateFields}
        handleDevicePairing={handleDevicePairingDialog}
        handleRemoteMonitoring={handleRemoteMonitoring}
        handleNetworkMemberAlertStatusUpdate={onNetworkMemberAlertStatusUpdate}
        remoteMonitoring={patientFormData.remoteMonitoring!}
        showSavingStatus={showSavingStatus}
        userDataLoading={
          isLoadingPatientData || isNetworkMemberAlertStatusLoading
        }
        isUserDataUpdating={isUpdatingPatientData}
        patientNetwork={patientNetwork}
        getPatientNetworkList={getPatientNetworkList}
        isPatientNetworkDataLoading={isPatientNetworkDataLoading}
        currentTab="EditPatient"
        physical_caregem={physical_caregemP}
        physical_rpm={physical_rpmP}
        digital_cg_sign_date={digital_cg_sign_date}
        digital_rpm_sign_date={digital_rpm_sign_date}
        physicalCaregem={physicalCaregemConsentP}
        physicalRPM={physicalRPMConsentP}
        // onConsentChange={handleConsentChange}
        handleUpdateParent={handleUpdateParent}
        checkCaregemPhysicalConsent={setPhysicalCaregemConsentC}
        checkRpmPhysicalConsent={setPhysicalRPMConsentC}
        isCaregemPhysicalConsentChecked={physicalCaregemConsentC}
        isRpmPhysicalConsentChecked={physicalRPMConsentC}
        getPatientList={getPatientList}
      />

      {patientFormData.openDevicePairing && (
        <DevicePairing
          userData={patientUserData}
          openDevicePairing={patientFormData.openDevicePairing}
          handleDevicePairing={handleDevicePairingDialog}
          reloadUserData={reloadUserData}
          handleRemoteMonitoring={handleRemoteMonitoring}
          patientNetwork={patientNetwork}
        />
      )}

      <ExceptionDialog
        open={isExceptionDialogOpen}
        handleClose={handleExceptionDialogClose}
        errorCode={errorCode}
        handleAddUserDialogClose={handleUpdateDialogClose}
        enteredFormData={putUpdateFormData(patientId)}
        existingUserData={existingUserData}
        fetchNewDataList={fetchPatients}
        role={UserRole.patient}
      />

      <AutomationDialog
        open={isAutomationDialogOpen}
        handleAutomationAccept={handleAutomationAccept}
        handleClose={handleAutomationDialogClose}
        patientUserData={patientUserData}
      />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Patients;
