import React, { useContext, useEffect, useMemo, useState } from 'react';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { createApiClient } from 'api/apiClient';
import I18nKey from 'lib/I18nKeys';
import { useIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import './BillingLogs.css';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { BillingReportsStatus } from 'api/SharedTypes';
import { Search } from '@mui/icons-material';
import { UserContext, UserContextState } from 'contexts/userContext';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const BillingLogs: React.FC = () => {
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const intl = useIntl();
  const [ERROR] = [I18nKey.ERROR_MESSAGE].map(key =>
    intl.formatMessage({ id: key }),
  );
  const currentYear = new Date().getFullYear();

  const [localSearchProvider, setLocalSearchProvider] = useState<string>('');
  const [isStatusSelect, setStatusSelect] = useState<string>('');
  const [isYearSelect, setYearSelect] = useState<any>(currentYear);

  const {
    isFetching: isBillingReportsLoading,
    isError,
    data: billingLogsResp,
  } = useQuery('get-billing-reports', () => apiClient.getBillingLogs());

  const [filteredUsers, setFilteredUsers] = useState<any>();

  const billingLogs = useMemo(() => {
    if (billingLogsResp) {
      if (isArray(billingLogsResp)) {
        setFilteredUsers(billingLogsResp);
        return billingLogsResp;
      }
    }
    return [];
  }, [billingLogsResp]);

  const convertUTCDateToLocal = (dateString: string) => {
    const localDateTime = DateTime.fromISO(dateString, {
      zone: 'utc',
    }).toLocal();
    const formattedDateTime = localDateTime.toFormat('MMM-dd-yyyy hh:mm a');
    return formattedDateTime;
  };

  const convertUTCYearToLocal = (dateString: string) => {
    const localDateTime = DateTime.fromISO(dateString, {
      zone: 'utc',
    }).toLocal();
    const formattedYear = localDateTime.toFormat('yyyy');
    return formattedYear;
  };

  // useEffect(() => {
  //   setData(filteredUsers);
  // }, [filteredUsers]);

  // useEffect(() => {
  //   const searchResults =
  //     !isEmpty(billingLogs) && isArray(billingLogs)
  //       ? billingLogs.filter(item => {
  //           item?.prov
  //             ?.toLowerCase()
  //             .includes(localSearchProvider.toLowerCase());
  //         })
  //       : [];
  //   setFilteredUsers(localSearchProvider ? searchResults : billingLogs);
  // }, [localSearchProvider, isStatusSelect, isYearSelect, billingLogsResp]);

  useEffect(() => {
    setFilteredUsers(
      billingLogs?.filter(item => {
        return convertUTCDateToLocal(item?.date_p).includes(
          currentYear.toString(),
        );
      }),
    );
  }, [billingLogs]);

  const filterAllData = (provider, status, year) => {
    const searchResults = billingLogs?.filter(item => {
      return (
        item?.prov?.toLowerCase().includes(provider.toLowerCase()) &&
        item?.billing_status?.toLowerCase().includes(status.toLowerCase()) &&
        convertUTCDateToLocal(item?.date_p).includes(year)
      );
    });
    setFilteredUsers(searchResults);
  };

  const localSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLocalSearchProvider(event.target.value);

  const prepareDataTableRows = () => {
    // var groupedData = groupBy(
    //   billingLogs,
    //   record =>
    //     new Date(record.date_p).getUTCMonth() +
    //     '.' +
    //     new Date(record.date_p).getFullYear(),
    // );
    var groupedData = groupBy(
      filteredUsers,
      record =>
        new Date(record.date_p).getUTCMonth() +
        '.' +
        new Date(record.date_p).getFullYear(),
    );
    var sortedKeys = Object.keys(groupedData)
      .sort((x, y) => {
        var split_x = x.split('.');
        var split_y = y.split('.');

        if (split_x[1] !== split_y[1]) {
          return parseInt(split_x[1]) - parseInt(split_y[1]);
        }

        return parseInt(split_x[0]) - parseInt(split_y[0]);
      })
      .reverse();

    var rows: JSX.Element[] = [];

    sortedKeys.forEach(month => {
      groupedData[month].sort((b, a) => {
        return new Date(a.date_p).getTime() - new Date(b.date_p).getTime();
      });

      Object.values(groupedData[month]).forEach(value => {
        const billSumbittedDate = convertUTCDateToLocal(
          value.bill_submitted_date,
        ).split(' ');

        const billCreatedDate = convertUTCDateToLocal(
          value.bill_created_date,
        ).split(' ');

        const billUpdatedDate = convertUTCDateToLocal(
          value.bill_updated_date,
        ).split(' ');
        rows.push(
          <tr style={{ fontSize: '12px' }}>
            <td>
              {DateTime.fromISO(value.date_p).toLocal().toFormat('MMM-dd-yyyy')}
            </td>
            <td>{value.prov}</td>
            <td>{value.patient}</td>
            <td>{value.code}</td>
            <td>{value.desc}</td>
            <td>{Number(value.call_duration / 60).toFixed(2)} mins</td>
            <td>
              {value.billing_status &&
              value.billing_status.toLowerCase() === 'approve'
                ? BillingReportsStatus.SUBMITTED
                : value.billing_status.toLowerCase() === 'errored'
                ? BillingReportsStatus.SUBMISSION_ERROR
                : value.billing_status}
            </td>
            <td>{value.caregem_bill_reference}</td>
            <td>{value.cds_bill_reference ? value.cds_bill_reference : '-'}</td>

            <td style={{ textAlign: 'left' }}>
              <p style={{ color: '#fd7e14' }}>
                Bill created on:{' '}
                {value.bill_created_date ? (
                  <span>
                    {billCreatedDate[0]}
                    <b> {billCreatedDate[1] + ' ' + billCreatedDate[2]}</b>
                  </span>
                ) : (
                  '-'
                )}
              </p>
              <p style={{ color: '#28a745' }}>
                Bill Submitted on:{' '}
                {value.bill_submitted_date ? (
                  <span>
                    {billSumbittedDate[0]}
                    <b> {billSumbittedDate[1] + ' ' + billSumbittedDate[2]}</b>
                  </span>
                ) : (
                  '-'
                )}
              </p>
              <p style={{ color: '#007bff' }}>
                Last Updated on:{' '}
                {value.bill_updated_date ? (
                  <span>
                    {billUpdatedDate[0]}
                    <b> {billUpdatedDate[1] + ' ' + billUpdatedDate[2]}</b>
                  </span>
                ) : (
                  '-'
                )}
              </p>
            </td>
          </tr>,
        );
      });

      var only_month = parseInt(month.split('.')[0]);

      rows.push(
        <tr key={month} style={{ backgroundColor: '#90caf9' }}>
          <td colSpan={10}>
            <b>{monthNames[only_month]}</b>
          </td>
        </tr>,
      );
    });

    return rows;
  };

  const handleSelectStatus = (event: SelectChangeEvent) => {
    if (event.target.value === 'All') {
      setStatusSelect('');
    } else {
      setStatusSelect(event.target.value);
    }
  };

  const handleSelectYear = (event: SelectChangeEvent) => {
    setYearSelect(event.target.value);
  };

  const { userData } = useContext<UserContextState>(UserContext);

  const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;

  const OrgID = userData?.userOrg;
  const OrgId: string =
    OrgID !== undefined ? OrgID.toString() : 'default value';

  const {
    data: dataDisableComponent,
    isLoading: isLoadingDisableComponent,
    refetch: refetchDisableComponent,
  } = useQuery(
    'disableComponent',
    async () =>
      createApiClient(accessToken!, controller).getDisableContent(OrgId),
    { staleTime: TWENTY_FOUR_HOURS, cacheTime: TWENTY_FOUR_HOURS },
  );

  useEffect(() => {
    refetchDisableComponent();
  }, [OrgId]);

  return (
    <>
      <div
        style={{ margin: '2%', textAlign: 'center' }}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            filterAllData(localSearchProvider, isStatusSelect, isYearSelect);
          }
        }}
      >
        <TextField
          name="noAutoFill"
          style={{
            marginRight: 20,
          }}
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
            },
          }}
          id="outlined-basic"
          label="Search Provider"
          variant="outlined"
          onChange={localSearch}
        />

        {/* <TextField
          name="noAutoFill"
          style={{
            marginRight: 20,
          }}
          id="outlined-basic"
          label="Search Patient"
          variant="outlined"
          onChange={localSearch}
        /> */}

        <FormControl style={{ marginRight: 20 }}>
          <InputLabel>Select Status</InputLabel>
          <Select
            sx={{ minWidth: 140 }}
            size="small"
            value={isStatusSelect == '' ? 'All' : isStatusSelect}
            // label={<p style={{ color: 'black' }}>Select Status</p>}
            label="Select Status"
            placeholder="Select Status"
            onChange={handleSelectStatus}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Approve'}>
              {BillingReportsStatus.SUBMITTED}
            </MenuItem>
            <MenuItem value={'Draft'}>{BillingReportsStatus.DRAFT}</MenuItem>
            <MenuItem value={'Errored'}>
              {BillingReportsStatus.SUBMISSION_ERROR}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ marginRight: 20 }}>
          <InputLabel id="Select Status">Select Year</InputLabel>
          <Select
            sx={{ minWidth: 140 }}
            size="small"
            value={isYearSelect ?? ''}
            label="Select Year"
            onChange={handleSelectYear}
          >
            <MenuItem value={currentYear}>{currentYear}</MenuItem>
            <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
            <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
            <MenuItem value={currentYear - 3}>{currentYear - 3}</MenuItem>
            <MenuItem value={currentYear - 4}>{currentYear - 4}</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          // className="fullHeight"
          startIcon={<Search />}
          onClick={() => {
            filterAllData(localSearchProvider, isStatusSelect, isYearSelect);
          }}
          style={{ padding: 7 }}
        >
          Search
        </Button>
      </div>

      <div>
        {isBillingReportsLoading ? (
          <div className="billingLogsCenterLoading">
            <CircularProgress />
          </div>
        ) : isError ? (
          <div className="billingLogsCenterLoading">
            <div className="red">{ERROR}</div>
          </div>
        ) : isEmpty(billingLogs) ? (
          <div className="billingLogsCenterLoading">
            <div>No Billing Reports Present</div>
          </div>
        ) : (
          <div className="billingLogs">
            <Button
              style={{ marginBottom: '10px' }}
              onClick={() => window.print()}
            >
              {intl.formatMessage({ id: I18nKey.BILLING_LOGS_TITLE })}
            </Button>
            <table id="patientSympData">
              <thead style={{ color: 'black', position: 'sticky', top: 0 }}>
                <tr>
                  <th>
                    <b>{intl.formatMessage({ id: I18nKey.DATE_OF_SERVICE })}</b>
                  </th>
                  <th>
                    <b>{intl.formatMessage({ id: I18nKey.PROVIDERS })}</b>
                  </th>
                  <th>
                    <b>{intl.formatMessage({ id: I18nKey.PATIENT })}</b>
                  </th>
                  <th>
                    <b>{intl.formatMessage({ id: I18nKey.CODE })}</b>
                  </th>
                  <th>
                    <b>{intl.formatMessage({ id: I18nKey.DESCRIPTION })}</b>
                  </th>
                  <th>
                    <b>Call Duration</b>
                  </th>
                  <th>
                    <b>Bill Status</b>
                  </th>
                  <th>
                    <b>CareGem Bill Reference</b>
                  </th>{' '}
                  <th>
                    <b>
                      {dataDisableComponent?.Nurse === 1
                        ? 'Biller Reference'
                        : 'CDS Bill Reference'}
                    </b>
                  </th>{' '}
                  <th>Key Dates</th>
                  {/* <th>
                    <b>Bill created on date</b>
                  </th>{' '}
                  <th>
                    <b>Bill Submitted on date</b>
                  </th>{' '}
                  <th>
                    <b>Last Updated on date</b>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {filteredUsers.length === 0 ? (
                  <div
                    style={{
                      width: '100vw',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 30,
                    }}
                  >
                    No Result Found!
                  </div>
                ) : (
                  prepareDataTableRows().map(component => component)
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default BillingLogs;
