import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
} from 'react';
import {
  ListItemText,
  Icon,
  IconButton,
  Snackbar,
  Menu,
  MenuItem,
  ListItemIcon,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  SelectChangeEvent,
  Popover,
  Typography,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  List,
  ListItem,
} from '@mui/material';
import { blue, green, grey, red } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { GetUserListResponse } from 'api/SharedTypes';
import { ProviderProps, UserRole, Icons } from 'services/Utils';
import './UsersList.css';
import { NavRoutes } from 'pages/NavRouter';
import { makeStyles, styled } from '@mui/styles';
import { createApiClient } from 'api/apiClient';
import MoreVert from '@mui/icons-material/MoreVert';
import { FormattedMessage, useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { useMutation } from 'react-query';
import { UserContext, UserContextState } from 'contexts/userContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Search } from '@mui/icons-material';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Shipped_ActiveSVG from 'images/Shipped-Active.svg';
import Delivered_ActiveSVG from 'images/Delivered-Active.svg';
import Failed_ActiveSVG from 'images/Failed-Active.svg';
import Processing_ActiveSVG from 'images/Processing-Active.svg';
import PaginationComponent from './PaginationUserList';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Consents from './Consents';
import { Colors } from 'pages/SurveyReport/Colors';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

interface UsersListProps {
  readonly users: GetUserListResponse[];
  readonly routes: NavRoutes;
  readonly onUserClick?: (id: number) => void;
  readonly searchPatientData?: (isLoaded?: boolean, body?: any) => void;
  readonly fetchNewDataList: (loadUpdatedData?: boolean) => void;
}

const UsersList: React.FC<UsersListProps> = ({
  users,
  onUserClick,
  routes,
  fetchNewDataList,
  searchPatientData,
}) => {
  const { isReadOnly, updateClick, setUpdateClick, setUserListingData } =
    useContext<UserContextState>(UserContext);
  const classes = useStyles();
  const intl = useIntl();
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const [searchText, setSearchText] = useState<string>('');
  const [localSearchText, setLocalSearchText] = useState<string>('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isAlertsDialogOpen, setIsAlertsDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUserRole, setSelectedRole] = useState<string>('');
  const [selectedAlertReceiver, setSelectedAlertReceiver] = useState<
    number | null
  >(null);
  const [missingNetwork, setMissingNetwork] = useState<string | boolean>(false);
  const [isRpmSelect, setRpmSelect] = useState<string>('');
  const [filteredUsers, setFilteredUsers] =
    useState<GetUserListResponse[]>(users);
  const [anchorE2, setAnchorE2] = React.useState<HTMLElement | null>(null);
  const [selectedValue, setSelectedValue] = useState('');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorE2(null);
  };

  const open = Boolean(anchorEl);

  const { isLoading: isUpdatingAlertStatus, mutate: updateAlertStatus } =
    useMutation(
      'update-alert-status',
      () =>
        apiClient.updateAlertStatus(selectedUserId!, {
          alert_receiver_status: selectedAlertReceiver === 1 ? 0 : 1,
        }),
      {
        onSuccess: () => {
          closeAlertsDialog();
          fetchNewDataList(true);
        },
      },
    );

  const { isLoading: isDeletingUser, mutate: deleteUserFromList } = useMutation(
    'delete-user',
    ({ id, role }: { id: number; role: UserRole }) =>
      apiClient.deleteUser(id, role),
    {
      onSuccess: () => {
        handleMenuClose();
        setSnackbarMessage('Deletion Successful!!');
        handleSnackBarOpen();
        closeDeleteDialog();
        fetchNewDataList(true);
      },
      onError: () => {
        setSnackbarMessage('Deletion Failed!!');
        handleSnackBarClose();
        closeDeleteDialog();
      },
    },
  );

  const {
    isLoading,
    mutate: callPatientList,
    data: patientListData,
  } = useMutation(
    'getUpdatedPatientList',
    () =>
      apiClient.getPatientList(false, {
        rpm: isRpmSelect,
        networks: missingNetwork,
        name: searchText,
        offset: currentPage,
      }),
    {
      onSuccess: (data: any) => {
        setUpdateClick(false);
        // eslint-disable-next-line
        setTotalPageCount(data[1]?.total_count);
        sessionStorage.setItem('ortderType', '');
      },
    },
  );

  const [patientList, setPatientList] = useState<any>();

  useEffect(() => {
    if (patientListData) {
      setPatientList(patientListData[0]);
      setUserListingData(patientListData[0]);
    }
  }, [patientListData]);

  const getRole = (role: UserRole) => {
    if (role === UserRole.patient || role === UserRole.caregiver) {
      return role;
    }
    return UserRole.provider;
  };

  const handleMenuControlClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
    role: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(id);
    setSelectedRole(role);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserId(null);
    setSelectedRole('');
  };

  const setDeleteDialogUserData = (id: number, role: string) => {
    setSelectedUserId(id);
    setSelectedRole(role);
    openDeleteDialog();
  };

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);
  const openAlertsDialog = () => setIsAlertsDialogOpen(true);
  const closeAlertsDialog = () => setIsAlertsDialogOpen(false);
  const alertReceiverStatus = () => updateAlertStatus();

  const alertsDialogOpen = (id: number, alert_receiver: number) => {
    openAlertsDialog();
    setSelectedUserId(id);
    setSelectedAlertReceiver(alert_receiver);
  };

  const username = (item: GetUserListResponse) => {
    if (ProviderProps.degree in item) return `${item.name}, ${item.degree}`;
    else return `${item.last_name}, ${item.first_name}`;
  };

  const specialty = (item: GetUserListResponse) => {
    if (ProviderProps.specialty in item && ProviderProps.groupName in item)
      return `${item.specialty}, ${item.group}`;
    else return ``;
  };

  const alertReceiver = (item: GetUserListResponse) => {
    if (ProviderProps.alert_receiver in item) {
      return item.alert_receiver === 1
        ? Icons.notifications_on
        : Icons.notifications_off;
    }
  };

  const alertReceiverColor = (item: GetUserListResponse) => {
    if (ProviderProps.alert_receiver in item) {
      return item.alert_receiver === 1 ? blue[800] : grey[500];
    }
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value);

  const localSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLocalSearchText(event.target.value);

  const deleteUser = () =>
    deleteUserFromList({
      id: selectedUserId!,
      role: getRole(selectedUserRole as UserRole),
    });

  const handleSnackBarOpen = () => setIsSnackbarOpen(true);

  const handleSnackBarClose = () => setIsSnackbarOpen(false);

  const handleSelectMissing = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setMissingNetwork(true);
    } else {
      setMissingNetwork('');
    }
  };

  const handleSelectRpm = (event: SelectChangeEvent) => {
    if (event.target.value === 'A') {
      setRpmSelect('');
      setSelectedValue(event.target.value);
    } else {
      setRpmSelect(event.target.value);
      setSelectedValue(event.target.value);
    }
  };

  const [data, setData] = useState(
    routes === NavRoutes.patients ? patientList : users,
  );
  useEffect(() => {
    setData(routes === NavRoutes.patients ? patientList : filteredUsers);
  }, [routes, patientList, filteredUsers]);

  useEffect(() => {
    const searchResults =
      !isEmpty(users) && isArray(users)
        ? users.filter((user: GetUserListResponse) =>
            user?.name?.toLowerCase().includes(localSearchText.toLowerCase()),
          )
        : [];
    setFilteredUsers(localSearchText ? searchResults : users);
  }, [localSearchText, users]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: 12,
      border: '1px solid #dadde9',
    },
  }));

  const sortedOrgList = useMemo(() => {
    if (!data) {
      return;
    }
    const sortedData = [...data].filter(item => item.last_name);
    return sortedData.sort((a: any, b: any) =>
      a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()),
    );
  }, [data]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      callPatientList();
    }
  };

  const [currentItems, setCurrentItems] = useState<any>([]);
  const itemsPerPage = (searchText ? patientList?.length : 25) ?? 25;
  const [currentPage, setCurrentPage] = useState<number>();

  const handlePageChange = (page: any) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentItems(sortedOrgList?.slice(startIndex, endIndex));
  };

  useEffect(() => {
    handlePageChange(1);
  }, [sortedOrgList]);

  useEffect(() => {
    callPatientList();
  }, [updateClick, currentPage]);

  const [totalPageCount, setTotalPageCount] = useState<any>();

  //new consent upload
  const [isConsentModalOpen, setIsConsentModalOpen] = useState<boolean>(false);
  const [isOpenConsentForm, setIsOpenConsentForm] = useState<boolean>(false);
  const [isSelectedConsent, setIsSelectedConsent] = useState<string>('');

  useEffect(() => {
    filterConsentList();
  }, [isConsentModalOpen]);

  const filterConsentList = () => {
    const filteredItem = currentItems.filter(
      (item: any) => item.sign_date !== null,
    );
  };

  const [consentName, setConsentName] = useState<any>('');
  const [byteString, setByteString] = useState<any>('');
  const [signerType, setSignerType] = useState<any>('');
  const [signType, setSignType] = useState<any>('');
  const [patientExtId, setPatientExtId] = useState<any>('');
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const {
    isLoading: uploadConsentLoading,
    mutate: uploadConsent,
    data: uploadConsentData,
  } = useMutation(
    [consentName, byteString, signerType, signType, patientExtId],
    () =>
      apiClient.postConsent(
        consentName,
        byteString,
        signerType,
        signType,
        patientExtId,
      ),
    {
      onSuccess: (data: any) => {
        window.location.reload();
      },
    },
  );

  const isConsentSigned = (consent: any) => {
    if (!consent) return;
    const Consents: any = consent?.consents?.filter(
      (signed: any) => signed.sign_date === null,
    );
    if (Consents.length === 0) return 0;
    if (Consents.length === 1 && Consents[0]?.consent_name === 'caregem')
      return 2;
    if (Consents.length === 1) return 1;
    if (Consents.length === 2) return 2;
  };

  const [confirmatiomModal, setConfirmationModal] = useState<boolean>(false);

  return (
    <div className="fullWidth" onKeyDown={handleKeyPress}>
      <div className="filter-grp">
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            width: '90%',
          }}
        >
          <TextField
            name="noAutoFill"
            style={{
              marginRight: routes === NavRoutes.patients ? 20 : 0,
              width: routes === NavRoutes.patients ? '45%' : '100%',
            }}
            sx={{
              '& .MuiInputBase-root': {
                height: '40px',
              },
            }}
            id="outlined-basic"
            label={
              routes === NavRoutes.patients
                ? 'Search Patient or IMEI'
                : 'Search'
            }
            variant="outlined"
            onChange={
              routes === NavRoutes.patients
                ? handleSearchTextChange
                : localSearch
            }
          />

          {routes === NavRoutes.patients && (
            <>
              <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
                <InputLabel>RPM Enabled</InputLabel>
                <Select
                  value={selectedValue}
                  label="RPM ENABLED"
                  onChange={handleSelectRpm}
                >
                  <MenuItem value={'A'}>All</MenuItem>
                  <MenuItem value={'Y'}>Yes</MenuItem>
                  <MenuItem value={'N'}>No</MenuItem>
                  <MenuItem value={'I'}>Pending</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          {routes === NavRoutes.patients && (
            <>
              <FormControl
                sx={{ m: 1, minWidth: 200 }}
                style={{
                  width: 230,
                  marginTop: 15,
                }}
                size="small"
                className="fullWidth"
              >
                <FormControlLabel
                  control={<Checkbox onChange={handleSelectMissing} />}
                  label="Missing network details"
                />
              </FormControl>
            </>
          )}
        </div>

        {routes === NavRoutes.patients && (
          <FormControl
            sx={{ minWidth: 120 }}
            size="small"
            className="searchButton"
          >
            <Button
              variant="outlined"
              // className="fullHeight"
              startIcon={<Search />}
              onClick={() => {
                callPatientList();
              }}
              style={{ padding: 7 }}
            >
              Search
            </Button>
          </FormControl>
        )}
      </div>

      {/**********************  table starts *********************/}
      <TableContainer className="patientsListing" component={Paper}>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              {routes === NavRoutes.patients ? (
                <TableRow>
                  <TableCell>Patient</TableCell>
                  <TableCell align="center">DOB</TableCell>
                  <TableCell align="center">Consent</TableCell>
                  <TableCell align="center">Device&nbsp;</TableCell>
                  <TableCell align="left">&nbsp;</TableCell>
                </TableRow>
              ) : (
                <TableRow></TableRow>
              )}
            </TableHead>

            <TableBody
              style={{
                cursor: 'pointer',
              }}
              className="table-row"
            >
              {isEmpty(patientList) && isArray(patientList) && (
                <div className="noDataContainer">
                  <div>No Data</div>
                </div>
              )}
              {!isEmpty(patientList) && !isArray(patientList) && (
                <div className="red">Invalid API Response</div>
              )}

              {currentItems?.map(
                item =>
                  username(item) !== 'undefined' && (
                    <TableRow key={item.id}>
                      <TableCell
                        scope="row"
                        align="left"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 15,
                        }}
                        onClick={() => onUserClick?.(item.id)}
                      >
                        {item.role !== UserRole.patient &&
                          item.role !== UserRole.caregiver && (
                            <Icon style={{ color: alertReceiverColor(item) }}>
                              {alertReceiver(item)}{' '}
                            </Icon>
                          )}
                        {item.network_count! !== 0 ? (
                          item.role !== UserRole.caregiver && (
                            <Icon
                              style={{
                                color:
                                  item.remote_monitoring === 'Y'
                                    ? green[500]
                                    : item.remote_monitoring === 'P'
                                    ? '#f2d720'
                                    : item.remote_monitoring === 'I'
                                    ? '#f2d720'
                                    : grey[500],
                              }}
                            >
                              {Icons.settings_remote}
                            </Icon>
                          )
                        ) : (
                          <Icon
                            style={{
                              color: red[500],
                            }}
                          >
                            {Icons.warning}
                          </Icon>
                        )}
                        {username(item)} <br />
                        {specialty(item)}
                      </TableCell>
                      {item.role === UserRole.patient && (
                        <TableCell
                          align="center"
                          onClick={() => onUserClick?.(item.id)}
                        >
                          {item?.dob}
                        </TableCell>
                      )}

                      {item.role === UserRole.patient && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              setIsConsentModalOpen(!isConsentModalOpen);
                              setSelectedUser(item);
                            }}
                          >
                            <UploadFileRoundedIcon
                              fontSize="medium"
                              style={{
                                color:
                                  isConsentSigned(item) === 2
                                    ? '#90caf9'
                                    : isConsentSigned(item) === 1
                                    ? 'rgb(242, 215, 32)'
                                    : 'rgb(76, 175, 80)',
                                backgroundColor: 'transparent',
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      )}

                      {item.role === UserRole.patient && (
                        <TableCell
                          align="center"
                          onClick={() => onUserClick?.(item.id)}
                          style={{ zIndex: '999' }}
                        >
                          {item?.remote_monitoring === 'I' ? (
                            <>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <em>{item?.status_description}</em>
                                  </React.Fragment>
                                }
                                placement="top"
                              >
                                <img
                                  src={
                                    item?.bodytrace_status == 'Submitted' ||
                                    item?.bodytrace_status == 'Pending' ||
                                    item?.bodytrace_status == 'Processing'
                                      ? Processing_ActiveSVG
                                      : item?.bodytrace_status == 'Fulfilled'
                                      ? Shipped_ActiveSVG
                                      : item?.bodytrace_status == 'Delivered'
                                      ? Delivered_ActiveSVG
                                      : item?.bodytrace_status == 'Exception' ||
                                        item?.bodytrace_status == 'Cancelled' ||
                                        item?.bodytrace_status == 'Failed'
                                      ? Failed_ActiveSVG
                                      : ''
                                  }
                                  alt={item?.status_description}
                                />
                              </HtmlTooltip>
                              {/* <Tooltip title={item?.status_description}>
                                <text>{item?.bodytrace_status}</text>
                              </Tooltip> */}
                            </>
                          ) : (
                            <span>{item?.imei}</span>
                          )}

                          {/* {Math.floor(100000 + Math.random() * 900000)} */}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        {routes === NavRoutes.patients ||
                        routes === NavRoutes.caregivers ? (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            disabled={isReadOnly}
                            onClick={() =>
                              setDeleteDialogUserData(item.id, item.role)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              edge="end"
                              aria-label="more"
                              disabled={isReadOnly}
                              onClick={e =>
                                handleMenuControlClick(
                                  e,
                                  item.id,
                                  UserRole.provider,
                                )
                              }
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={selectedUserId === item.id}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={openDeleteDialog}>
                                <ListItemIcon>
                                  <DeleteIcon fontSize="medium" />
                                </ListItemIcon>
                                <ListItemText className="networkUserNameText">
                                  {intl.formatMessage({
                                    id: I18nKey.ARCHIVE_USER,
                                  })}
                                </ListItemText>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  alertsDialogOpen(
                                    item.id,
                                    item.alert_receiver!,
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <NotificationsIcon fontSize="medium" />
                                </ListItemIcon>
                                {item.alert_receiver === 1 ? (
                                  <ListItemText className="networkUserNameText">
                                    {intl.formatMessage({
                                      id: I18nKey.USER_NETWORK_ACTION_DEACTIVATE_ALERTS,
                                    })}
                                  </ListItemText>
                                ) : (
                                  <ListItemText className="networkUserNameText">
                                    {intl.formatMessage({
                                      id: I18nKey.USER_NETWORK_ACTION_ACTIVATE_ALERTS,
                                    })}
                                  </ListItemText>
                                )}
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        )}
        <>
          <PaginationComponent
            totalItems={totalPageCount}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
            setCurrentPage={setCurrentPage}
            routes={routes}
          />
        </>
      </TableContainer>

      {/**********************  table ends *********************/}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleSnackBarClose}
        message={snackbarMessage}
      />
      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog} fullWidth>
        <DialogTitle>
          {intl.formatMessage({
            id: I18nKey.ARCHIVE_USER_CONFIRM_DIALOG_TITLE,
          })}
        </DialogTitle>
        <DialogContent>
          {intl.formatMessage({
            id: I18nKey.ARCHIVE_USER_CONFIRM_DIALOG_CONTENT,
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>
            {intl.formatMessage({ id: I18nKey.CANCEL })}
          </Button>
          <Button onClick={deleteUser} disabled={isDeletingUser}>
            {isDeletingUser ? (
              <CircularProgress size={30} />
            ) : (
              intl.formatMessage({ id: I18nKey.CONFIRM })
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isAlertsDialogOpen}
        onClose={closeAlertsDialog}
        maxWidth={'lg'}
      >
        <DialogTitle>
          <Icon
            sx={{
              color: 'black',
            }}
          >
            {Icons.warning}
          </Icon>
          {intl.formatMessage({
            id: I18nKey.ALERT_RECEIVER_DIALOG_TITLE,
          })}
        </DialogTitle>
        <DialogContent sx={{ color: 'red' }}>
          <FormattedMessage
            id={I18nKey.ALERT_RECEIVER_DIALOG_CONTENT}
            values={{ separator: <br /> }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlertsDialog}>
            {intl.formatMessage({ id: I18nKey.CANCEL })}
          </Button>
          <Button
            onClick={alertReceiverStatus}
            disabled={isUpdatingAlertStatus}
          >
            {isUpdatingAlertStatus ? (
              <CircularProgress size={30} />
            ) : (
              intl.formatMessage({ id: I18nKey.CONFIRM })
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* consent modal option */}
      <Dialog
        open={isConsentModalOpen}
        onClose={() => setIsConsentModalOpen(false)}
        maxWidth={'lg'}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        <DialogTitle
          textAlign={'left'}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 31px',
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          <span>
            {selectedUser?.consents[0]?.sign_date === null ||
            selectedUser?.consents[1]?.sign_date === null
              ? 'SELECT CONSENT'
              : 'CONSENT STATUS'}
          </span>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setIsConsentModalOpen(false)}
            style={{ padding: '2px 2px' }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>

        <DialogContent>
          <div style={{ display: 'flex', gap: 20, padding: 5 }}>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpenConsentForm(!isOpenConsentForm);
                setIsSelectedConsent('rpm');
              }}
              disabled={
                selectedUser?.consents[1]?.sign_date !== null ? true : false
              }
              style={{
                backgroundColor:
                  selectedUser?.consents[1]?.sign_date !== null
                    ? selectedUser?.consents?.length > 1
                      ? 'green'
                      : 'grey'
                    : '',
                color: 'white',
              }}
            >
              {`RPM Consent ${
                selectedUser?.consents?.length > 1 &&
                selectedUser?.consents[1]?.sign_date !== null
                  ? 'Signed'
                  : ''
              }`}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpenConsentForm(!isOpenConsentForm);
                setIsSelectedConsent('caregem');
              }}
              disabled={
                selectedUser?.consents[0]?.sign_date !== null ? true : false
              }
              style={{
                backgroundColor:
                  selectedUser?.consents[0]?.sign_date !== null ? 'green' : '',
                color: 'white',
              }}
            >
              {`Caregem Consent ${
                selectedUser?.consents[0]?.sign_date !== null ? 'Signed' : ''
              }`}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/*Consent Form modal option */}
      <Dialog
        open={isOpenConsentForm}
        onClose={() => setIsOpenConsentForm(false)}
        fullWidth={true}
        maxWidth={'md'}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <DialogTitle textAlign={'left'}>
          {isSelectedConsent === 'caregem' ? 'CAREGEM CONSENT' : 'RPM CONSENT'}
        </DialogTitle>
        <DialogContent>
          <Consents
            selectedConsent={isSelectedConsent}
            setConsentName={setConsentName}
            setByteString={setByteString}
            setSignerType={setSignerType}
            setSignType={setSignType}
            setPatientExtId={setPatientExtId}
            selectedUser={selectedUser}
            byteString={byteString}
          />
        </DialogContent>
        <DialogActions
          style={{
            width: '100%',
            paddingTop: 20,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsOpenConsentForm(!isOpenConsentForm)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setConfirmationModal(!confirmatiomModal)}
            disabled={!byteString || !signerType || isLoading}
          >
            {uploadConsentLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmatiomModal}
        onClose={() => setConfirmationModal(!confirmatiomModal)}
        fullWidth={true}
        maxWidth={'xs'}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        <DialogTitle textAlign={'center'}>
          For Practice Admin use only
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => uploadConsent()}
            disabled={!byteString || !signerType || isLoading}
          >
            {uploadConsentLoading ? (
              <CircularProgress size={24} />
            ) : (
              'Confirm Consent'
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UsersList;
