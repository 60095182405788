import React, { useContext, useEffect, useState } from 'react';
import '../style.css';
import { UserContext } from 'contexts/userContext';

interface CareGemConsentFormProps {
  pName?: string;
  pDob?: string | undefined;
  sign?: string | undefined;
  // relative: string | null;
  pEmail?: string | undefined;
  signer?: string | null;
  clientLogo?: string | null;
  consentName?: string | null;
  orgName?: string | null;
  userInitial?: string;
  selectedUser?: any;
  checkBoxValue?: any;
}

const CareGemConsentForm: React.FC<CareGemConsentFormProps> = ({
  pName,
  pDob,
  sign,
  // relative,
  pEmail,
  signer,
  clientLogo,
  consentName,
  orgName,
  userInitial,
  selectedUser,
  checkBoxValue,
}) => {
  const { userData } = useContext(UserContext);
  const OrgID = userData?.userOrg;
  const OrgId: string =
    OrgID !== undefined ? OrgID.toString() : 'default value';

  const [patientName, SetPatientName] = useState(pName);
  const [patientDob, SetPatientDob] = useState(pDob);
  const [patientRealatedPersonName, setPatientRealatedPersonName] =
    useState(signer);
  const [patientInitial, setPatientInitial] = useState(userInitial);
  const [patientEmail, SetPatientEmail] = useState(pEmail);
  const [clientLogoCaregem, setClientLogoCaregem] = useState(
    `${process.env['REACT_APP_ASSET_ENDPOINT']}logo/${OrgId}/organisation_logo.jpg`,
  );
  const [practiceLogoCaregem, setPracticeLogoCaregem] = useState(
    `https://caregem-docs-us-east-1-654654614422.s3.amazonaws.com/logo/CareGemHealthLogo.png`,
  );

  useEffect(() => {
    console.log('selectedUser', selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    setPatientInitial(userInitial);
  }, [userInitial]);

  useEffect(() => {
    setPatientRealatedPersonName(signer);
  }, [signer]);

  const date = new Date();

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const formattedTodaysDate = `${month}/${day}/${year}`;

  return (
    <div className="consesntContainer">
      <div className="form-container" id="divToExport">
        <div className="header">
          <img
            className="logo-left"
            src={clientLogoCaregem}
            alt=""
            width={300}
          />
          <img
            className="logo-right"
            src={practiceLogoCaregem}
            alt=""
            width={180}
          />
        </div>
        <div className="consentFormHeader">
          <p style={{ fontWeight: 650, fontSize: 16 }}>
            CareGem Subscriber Agreement
          </p>

          <p className="paragraph">
            By accessing, downloading or using the CareGem platform
            (“Platform”), I and/or any person for whom I act on behalf of (as
            Power of Attorney, Guardian, or other Responsible party)
            (“Subscriber”), agree to the following:
          </p>
          <p className="paragraph">
            I have received and reviewed the document entitled Empowering
            Coordinated Healthcare by CareGem, and the CareGem Privacy Policy. I
            understand the information and instructions in the document, and
            have had the opportunity to have my questions answered about the
            CareGem program (‘Program’).
          </p>
          <p className="paragraph">
            I understand that use of the Platform is only intended to assist in,
            and not replace my current health treatment plan. I agree to
            continue to use the current systems available to me to access care,
            and not rely on the Program/Platform to deal with any urgent or
            non-urgent health-care needs. <br />I UNDERSTAND THAT THE PLATFORM
            IS NOT INTENDED FOR EMERGENCY USE AND IS NOT MONITORED 24/7. I WILL
            CALL 911 FOR IMMEDIATE MEDICAL EMERGENCIES.
          </p>
          <p className="paragraph">
            I understand that by enrolling in the Program, my personal health
            information will be shared with CareGem and its affiliate Althea.ai.
            The information collected about me may only be used in accordance
            with the current privacy policy of Practice and CareGem. I
            understand that my information will be kept confidential and secure
            on a network compliant with HIPAA, and will only be made available
            to Program staff, and my health-care team: (i) for billing,
            treatment and health care operations; (ii) for improvement of the
            Program or Platform; (iii) evaluation of Program’s impact on my
            care; and (iv) for health research activities (through the use of
            de-identified data).
          </p>
          <p className="paragraph">
            I understand that by signing this consent I have reviewed and agree
            to the Privacy Policy, Terms and Conditions and End User License
            Agreement of CareGem, available at{' '}
            <a href="http://www.caregem.com/legal">www.caregem.com/legal</a>. I
            have also received and agree to the Privacy Policy of Practice.
          </p>
          <p className="paragraph">
            Use of CareGem involves SMS messages for alerts and collaboration. I
            agree to be responsible for Message and Data rates that may apply to
            SMS messages sent to my phone.
          </p>
          <p className="paragraph">
            I have read and understood the information above and consent to
            enroll in the Program.
          </p>

          <p className="paragraph">
            Date accepted:
            <span className="patientData" style={{ marginLeft: 17 }}>
              {formattedTodaysDate}
            </span>
            (mm/dd/yyyy)
          </p>
          <p className="paragraph">
            Subscriber Name:
            <span className="patientData" style={{ marginLeft: 17 }}>
              {selectedUser.first_name + ' ' + selectedUser.last_name}
            </span>
          </p>
          <p className="paragraph">
            Signature of Patient :
            <span className="patientData" style={{ marginLeft: 18 }}>
              {!sign ? (
                <>{userInitial}</>
              ) : (
                <img
                  src={sign}
                  width={70}
                  height={35}
                  style={{ marginBottom: 25 }}
                  alt=""
                />
              )}
            </span>
          </p>
          <p className="paragraph">
            (Relationship of Authorized Person)
            <span className="patientData" style={{ marginLeft: 18 }}>
              {checkBoxValue}
            </span>
          </p>
          <p className="paragraph">
            Subscriber Email address:
            <span className="patientData" style={{ marginLeft: 17 }}>
              {selectedUser.email}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareGemConsentForm;
