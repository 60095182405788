import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import UsersList from '../../components/UsersList';
import isArray from 'lodash/isArray';
import { CircularProgress } from '@mui/material';
import { createApiClient } from 'api/apiClient';
import TabTopButton from 'components/TabTopButton';
import { GetUserListResponse, UpdateUserResponse, User } from 'api/SharedTypes';
import { FormFieldObject } from 'components/UserForm';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  FormFieldType,
  SubmitFormData,
  UserRole,
} from 'services/Utils';
import { UpdateDialog } from 'components/UpdateDialog';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { ExceptionDialog } from 'components/ExceptionDialog';
import { NavRoutes } from '../NavRouter';
import {
  sortByName,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import { useCaseManagerData } from './useCaseManagerData';
import { useMutation } from 'react-query';
import './style.css';
import { UserContextState, UserContext } from 'contexts/userContext';
import { useProviderDegrees } from 'services/useProviderDegrees';

const CaseManagers: React.FC = () => {
  const { isReadOnly } = useContext<UserContextState>(UserContext);
  const {
    caseManagerFormActions,
    caseManagerFormData,
    caseManagerUserData,
    clearForm,
    getFormSubmitData,
    setCaseManagerFormData,
  } = useCaseManagerData();

  const providerDegreeOptions = useProviderDegrees();

  const intl = useIntl();

  const accessToken = localStorage.getItem('access_token');
  const controller = useMemo(() => new AbortController(), []);
  const apiClient = createApiClient(accessToken!, controller);

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState<boolean>(false);
  const [isExceptionDialogOpen, setIsExceptionDialogOpen] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [existingUserData, setExistingUserData] = useState<User>({});

  const {
    isLoading,
    mutate: getCaseManagerList,
    isError,
    data: caseManagerListResp,
  } = useMutation(
    'case-manager-list',
    async (loadUpdatedData?: boolean) =>
      apiClient.getCaseManagersList(loadUpdatedData),
    {
      onError: error => {
        console.log('error ', error);
      },
    },
  );

  const { isLoading: isLoadingCaseManagerData, mutate: getCaseManagerData } =
    useMutation(
      'get-case-manager-data',
      async (id: number) => apiClient.getUserData(id, UserRole.provider),
      {
        onSuccess: response => {
          setCaseManagerFormData(response);
          caseManagerFormActions.setCaseManagerData(response);
        },
        onError: () => {
          handleUpdateDialogClose();
        },
      },
    );

  const {
    isLoading: isUpdatingCaseManagerData,
    mutate: updateCaseManagerData,
  } = useMutation(
    'update-case-manager-data',
    async (params: { details: SubmitFormData; id: number }) =>
      apiClient.updateProviderData(params.details, params.id),
    {
      onSuccess: response => {
        if (response.errorCode) {
          setExceptionDialogData(response);
        } else {
          handleUpdateDialogClose();
          getCaseManagerList(false);
        }
      },
    },
  );

  const caseManagers = useMemo(() => {
    if (caseManagerListResp) {
      if (isArray(caseManagerListResp)) {
        return sortByName(caseManagerListResp) as GetUserListResponse[];
      }
    }
    return [];
  }, [caseManagerListResp]);

  const handleExceptionDialogClose = () => setIsExceptionDialogOpen(false);

  const handleExceptionDialogOpen = () => setIsExceptionDialogOpen(true);

  const setExceptionDialogData = (response: UpdateUserResponse) => {
    handleExceptionDialogOpen();
    setErrorCode(response.errorCode!);
    setExistingUserData(response.existing_data!);
  };

  const handleUpdateDialogOpen = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    clearForm();
    caseManagerFormActions.setProviderId(null);
    caseManagerFormActions.setCaseManagerData(EMPTY_USER_RESPONSE_DATA);
    setIsUpdateDialogOpen(false);
  };

  const onCaseManagerClick = (id: number) => {
    handleUpdateDialogOpen();
    caseManagerFormActions.setProviderId(id);
    getCaseManagerData(id);
  };

  const updateCaseManager = async () => {
    const data = getFormSubmitData();
    updateCaseManagerData({
      details: { ...data, role: UserRole.case_manager },
      id: caseManagerFormData.providerId!,
    });
    caseManagerFormActions.setVerifyEmailChecked(false);
  };

  const fetchCaseManagers = useCallback(
    (loadUpdatedData?: boolean) => getCaseManagerList(loadUpdatedData),
    [getCaseManagerList],
  );

  const getUserName = () => {
    if (
      caseManagerFormData.firstName &&
      caseManagerFormData.lastName &&
      !caseManagerFormData.userName
    ) {
      apiClient
        .getUserName(
          caseManagerFormData.firstName,
          caseManagerFormData.lastName,
        )
        .then(response => caseManagerFormActions.setUserName(response));
    }
  };

  const handleRemoteMonitoring = () => {
    caseManagerFormData.remoteMonitoring === 'Y'
      ? caseManagerFormActions.setRemoteMonitoring('N')
      : caseManagerFormActions.setRemoteMonitoring('Y');
  };

  const caseManagerFields: FormFieldObject = {
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.firstName,
      onChange: value => caseManagerFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      onBlur: getUserName,
      isError: caseManagerFormData.firstName
        ? !validateName(caseManagerFormData.firstName)
        : false,
      errorText:
        !validateName(caseManagerFormData.firstName) &&
        caseManagerFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.lastName,
      onChange: value => caseManagerFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      onBlur: getUserName,
      isError: caseManagerFormData.lastName
        ? !validateName(caseManagerFormData.lastName)
        : false,
      errorText:
        !validateName(caseManagerFormData.lastName) &&
        caseManagerFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      isDisabled: true,
      value: caseManagerFormData.userName,
      onChange: value => caseManagerFormActions.setUserName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_USERNAME,
      }),
    },

    degree: {
      type: FormFieldType.select,
      selectOptions: providerDegreeOptions,
      isRequired: true,
      value: caseManagerFormData.degree,
      onChange: value => caseManagerFormActions.setDegree(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEGREE,
      }),
      isDisabled: isReadOnly,
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      value: caseManagerFormData.email.toLowerCase(),
      onChange: value => caseManagerFormActions.setEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: caseManagerFormData.email
        ? !validateEmail(caseManagerFormData.email)
        : false,
      errorText:
        !validateEmail(caseManagerFormData.email) && caseManagerFormData.email
          ? 'Please enter a valid email'
          : '',
      isDisabled: isReadOnly,
    },
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        caseManagerFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
      hide: true,
    },
    speciality: {
      type: FormFieldType.select,
      isRequired: true,
      value: caseManagerFormData.speciality,
      onChange: value => caseManagerFormActions.setSpeciality(value),
      selectOptions: ExportConstants.specialtyOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_SPECIALITY,
      }),
      isDisabled: isReadOnly,
    },
    office_tel_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: caseManagerFormData.officeTelCountryCode,
      onChange: value => caseManagerFormActions.setOfficeTelCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    office_tel: {
      type: FormFieldType.mask,
      isRequired: true,
      value: caseManagerFormData.landline,
      onChange: value => caseManagerFormActions.setLandline(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_TELEPHONE,
      }),
      isDisabled: isReadOnly,
    },
    cell_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: caseManagerFormData.cellCountryCode,
      onChange: value => caseManagerFormActions.setCellCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL_COUNTRY_CODE,
      }),
      isDisabled: isReadOnly,
    },
    cell: {
      type: FormFieldType.mask,
      isRequired: true,
      value: caseManagerFormData.mobile,
      onChange: value => caseManagerFormActions.setMobile(value),
      mask: '###-###-####',
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_MOBILE_NUMBER,
      }),
      isDisabled: isReadOnly,
    },
    group_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.groupName,
      onChange: value => caseManagerFormActions.setGroupName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_GROUP_NAME,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    office_addr_1: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.officeAddr1,
      onChange: value => caseManagerFormActions.setOfficeAddr1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_OFFICE_ADDRESS_1,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },
    office_addr_2: {
      type: FormFieldType.text,
      isRequired: false,
      value: caseManagerFormData.officeAddr2,
      onChange: value => caseManagerFormActions.setOfficeAddr2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_OFFICE_ADDRESS_2,
      }),
      maxLength: 40,
      isDisabled: isReadOnly,
    },

    address_city: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.city,
      onChange: value => caseManagerFormActions.setCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS_CITY,
      }),
      maxLength: 20,
      isDisabled: isReadOnly,
    },

    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: caseManagerFormData.state,
      onChange: value => caseManagerFormActions.setState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
      isDisabled: isReadOnly,
    },

    address_zip: {
      type: FormFieldType.mask,
      isRequired: true,
      mask: '######',
      value: caseManagerFormData.zipCode,
      onChange: value => caseManagerFormActions.setZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
      isDisabled: isReadOnly,
    },
    licenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: caseManagerFormData.licenseNumber,
      onChange: value => caseManagerFormActions.setLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LICENSE_NUMBER,
      }),
      maxLength: 25,
      isDisabled: isReadOnly,
    },
    nursingLicenseNumber: {
      type: FormFieldType.text,
      isRequired: false,
      value: caseManagerFormData.nursingLicenseNumber,
      onChange: value => caseManagerFormActions.setNursingLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_NURSING_LICENSE_NUMBER,
      }),
      maxLength: 25,
      isDisabled: isReadOnly,
    },
  };

  const caseManagerUpdateFields: FormFieldObject = {
    ...caseManagerFields,
    verifyEmail: {
      type: FormFieldType.checkbox,
      isRequired: false,
      onChange: value => {
        caseManagerFormActions.setVerifyEmailChecked(value === 'true');
      },
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_VERIFY_EMAIL,
      }),
      tooltip:
        '(Optional) By checking this box you have confirmed that this email is verified',
      isDisabled: isReadOnly,
    },
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.firstName,
      onChange: value => caseManagerFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      isError: caseManagerFormData.firstName
        ? !validateName(caseManagerFormData.firstName)
        : false,
      errorText:
        !validateName(caseManagerFormData.firstName) &&
        caseManagerFormData.firstName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.lastName,
      onChange: value => caseManagerFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      isError: caseManagerFormData.lastName
        ? !validateName(caseManagerFormData.lastName)
        : false,
      errorText:
        !validateName(caseManagerFormData.lastName) &&
        caseManagerFormData.lastName
          ? 'Please do not use spaces'
          : '',
      maxLength: 16,
      isDisabled: isReadOnly,
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      value: caseManagerFormData.userName,
      label: intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME }),
      isDisabled: true,
    },
  };
  useEffect(() => {
    fetchCaseManagers();
    return () => {
      controller.abort();
    };
  }, [controller, fetchCaseManagers]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <TabTopButton
        role={UserRole.case_manager}
        getFormSubmitData={getFormSubmitData}
        clearForm={clearForm}
        formFields={caseManagerFields}
        fetchNewDataList={fetchCaseManagers}
        handleRemoteMonitoring={handleRemoteMonitoring}
        remoteMonitoring={caseManagerFormData.remoteMonitoring!}
      />
      {isLoading ? (
        <div className="caseManagerListCenterLoading">
          <CircularProgress />
        </div>
      ) : isError ? (
        <div className="caseManagerListCenterLoading">
          <div className="red">Failed to Fetch Case Manager List</div>
        </div>
      ) : (
        <UsersList
          users={caseManagers}
          routes={NavRoutes.caseManagers}
          onUserClick={onCaseManagerClick}
          fetchNewDataList={fetchCaseManagers}
        />
      )}
      <UpdateDialog
        handleClose={handleUpdateDialogClose}
        open={isUpdateDialogOpen}
        currentUserData={caseManagerUserData}
        role={UserRole.case_manager}
        handleUpdate={updateCaseManager}
        formFields={caseManagerUpdateFields}
        remoteMonitoring={caseManagerFormData.remoteMonitoring!}
        handleRemoteMonitoring={handleRemoteMonitoring}
        userDataLoading={isLoadingCaseManagerData}
        isUserDataUpdating={isUpdatingCaseManagerData}
      />
      <ExceptionDialog
        open={isExceptionDialogOpen}
        handleClose={handleExceptionDialogClose}
        errorCode={errorCode}
        handleAddUserDialogClose={handleUpdateDialogClose}
        enteredFormData={getFormSubmitData()}
        existingUserData={existingUserData}
        role={UserRole.case_manager}
        fetchNewDataList={fetchCaseManagers}
      />
    </div>
  );
};

export default CaseManagers;
