import { useState } from 'react';
import { DateTime } from 'luxon';
import { UserDataResponse } from 'api/SharedTypes';
import ExportConstants, {
  EMPTY_USER_RESPONSE_DATA,
  SubmitFormData,
} from 'services/Utils';

export const usePatientData = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [landline, setLandline] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [cellCountryCode, setCellCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [homeTelCountryCode, setHomeTelCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString(),
  );
  const [dob, setDOB] = useState<DateTime | null>(null);
  const [gender, setGender] = useState<string>('');
  const [homeAddr1, setHomeAddr1] = useState<string>('');
  const [homeAddr2, setHomeAddr2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [licenseNumber, setLicenseNumber] = useState<string>('');
  const [refUID, setRefUID] = useState<string | undefined>(undefined);
  const [deviceIMEI, setDeviceIMEI] = useState<string>('');
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [remoteMonitoring, setRemoteMonitoring] = useState<string>('N');
  const [openDevicePairing, setDevicePairing] = useState<boolean>(false);
  const [ssn, setSsn] = useState<string>('');
  const [verifyEmailChecked, setVerifyEmailChecked] = useState<boolean>(false);
  const [physical_caregem_checked, setPhysical_caregem_checked] = useState<
    string | null | boolean | number
  >('1');
  const [physical_rpm_checked, setPhysical_rpm_checked] = useState<
    string | null | boolean | number
  >('1');
  const [vbcId, setVbcId] = useState<string>('');

  const [patientData, setPatientData] = useState<UserDataResponse>(
    EMPTY_USER_RESPONSE_DATA,
  );

  const patientFormData = {
    firstName,
    middleName,
    lastName,
    userName,
    email,
    landline,
    mobile,
    cellCountryCode,
    homeTelCountryCode,
    dob,
    gender,
    homeAddr1,
    homeAddr2,
    city,
    state,
    zipCode,
    licenseNumber,
    refUID,
    deviceIMEI,
    startDate,
    remoteMonitoring,
    openDevicePairing,
    ssn,
    verifyEmailChecked,
    vbcId,
    physical_caregem_checked,
    physical_rpm_checked,
  };

  const patientFormActions = {
    setFirstName,
    setMiddleName,
    setLastName,
    setUserName,
    setEmail,
    setLandline,
    setMobile,
    setCellCountryCode,
    setHomeTelCountryCode,
    setDOB,
    setGender,
    setHomeAddr1,
    setHomeAddr2,
    setCity,
    setState,
    setZipCode,
    setLicenseNumber,
    setRefUID,
    setDeviceIMEI,
    setStartDate,
    setRemoteMonitoring,
    setDevicePairing,
    setSsn,
    setPatientData,
    setVerifyEmailChecked,
    setVbcId,
    // setPhysical_caregem_checked,
    // setPhysical_rpm_checked,
  };

  const setPatientFormData = (data: UserDataResponse) => {
    setPhysical_caregem_checked(data.physical_caregem);
    setPhysical_rpm_checked(data.physical_rpm);
    setUserName(data.username);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setMiddleName(data.middle_name);
    setCellCountryCode(
      data.cell_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setHomeTelCountryCode(
      data.home_tel_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setEmail(data.email);
    setDOB(DateTime.fromFormat(data.dob, 'MM-dd-yyyy'));
    setMobile(data.cell);
    setLandline(data.home_tel);
    setHomeAddr1(data.home_addr_1);
    setHomeAddr2(data.home_addr_2);
    setCity(data.address_city);
    setState(data.state);
    setZipCode(data.address_zip);
    setLicenseNumber(data.drive_license_number);
    setGender(data.gender);
    setRemoteMonitoring(data.remote_monitoring);
    setDeviceIMEI(data.device_pairing!);
    setRefUID(data.ref_uid);
    setStartDate(
      data.start_date
        ? DateTime.fromFormat(data.start_date!.split(' ')[0], 'MM-dd-yyyy')
        : null,
    );
    setSsn(data.ssn!);
    setVbcId(String(data.program_id));
    setPhysical_caregem_checked(data.physical_caregem);
    setPhysical_rpm_checked(data.physical_rpm);
  };

  const getFormSubmitData = (): SubmitFormData => ({
    username: userName,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    cell_country_code: cellCountryCode,
    home_tel_country_code: homeTelCountryCode,
    email: email,
    dob: dob?.toFormat('MM-dd-yyyy') || '',
    cell: mobile.replaceAll('-', ''),
    home_tel: landline.replaceAll('-', ''),
    home_addr_1: homeAddr1,
    home_addr_2: homeAddr2,
    address_city: city,
    state: state,
    address_zip: zipCode,
    drive_license_number: licenseNumber,
    gender: gender,
    ssn: ssn,
    remote_monitoring: remoteMonitoring!,
    program_id: Number(vbcId),
    physical_caregem: physical_caregem_checked,
    physical_rpm: physical_rpm_checked,
  });

  const putUpdateFormData = (patientId: number | null): SubmitFormData =>
    verifyEmailChecked
      ? {
          first_name: firstName,
          last_name: lastName,
          middle_name: middleName,
          cell_country_code: cellCountryCode,
          home_tel_country_code: homeTelCountryCode,
          email: email,
          dob: dob?.toFormat('MM-dd-yyyy') || '',
          cell: mobile,
          home_tel: landline,
          home_addr_1: homeAddr1,
          home_addr_2: homeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          gender: gender,
          id: patientId ? patientId.toString() : '',
          ssn: ssn,
          remote_monitoring: remoteMonitoring!,
          ref_uid: refUID || '',
          email_verified: verifyEmailChecked,
          program_id: Number(vbcId),
          physical_caregem: physical_caregem_checked,
          physical_rpm: physical_rpm_checked,
        }
      : {
          first_name: firstName,
          last_name: lastName,
          middle_name: middleName,
          cell_country_code: cellCountryCode,
          home_tel_country_code: homeTelCountryCode,
          email: email,
          dob: dob?.toFormat('MM-dd-yyyy') || '',
          cell: mobile,
          home_tel: landline,
          home_addr_1: homeAddr1,
          home_addr_2: homeAddr2,
          address_city: city,
          state: state,
          address_zip: zipCode,
          drive_license_number: licenseNumber,
          gender: gender,
          id: patientId ? patientId.toString() : '',
          ssn: ssn,
          remote_monitoring: remoteMonitoring!,
          ref_uid: refUID || '',
          program_id: Number(vbcId),
          physical_caregem: physical_caregem_checked,
          physical_rpm: physical_rpm_checked,
        };

  const clearForm = () => {
    setUserName('');
    setFirstName('');
    setLastName('');
    setCellCountryCode(ExportConstants.countryCodeOptions[0].value.toString());
    setHomeTelCountryCode(
      ExportConstants.countryCodeOptions[0].value.toString(),
    );
    setMiddleName('');
    setEmail('');
    setDOB(null);
    setGender('');
    setLandline('');
    setMobile('');
    setGender('');
    setHomeAddr1('');
    setHomeAddr2('');
    setCity('');
    setState('');
    setZipCode('');
    setLicenseNumber('');
    setSsn('');
    setRemoteMonitoring('N');
    setRefUID('');
    setStartDate(null);
    setDevicePairing(false);
    setDeviceIMEI('');
    setVbcId('');
  };

  return {
    patientUserData: patientData,
    patientFormData,
    patientFormActions,
    setPatientFormData,
    getFormSubmitData,
    putUpdateFormData,
    clearForm,
  };
};
