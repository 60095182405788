import { Backdrop, Button, CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.css';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { createApiClient } from 'api/apiClient';
import { UserContext, UserContextState } from 'contexts/userContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactToPrint from 'react-to-print';

const RPMEligiblePatient: React.FC = () => {
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const apiClient = createApiClient(accessToken!, controller);
  const [selectStartDate, setSelectStartDate] = useState<Dayjs | null>(null);
  const [selectEndDate, setSelectEndDate] = useState<Dayjs | null>(null);
  const { userData } = useContext<UserContextState>(UserContext);
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [providerNames, setProviderNames] = useState<string[]>([]);
  const [selectedProvider, setSelectedProvider] =
    useState<string>('All Providers');
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const componentRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (transformedData) setFilteredList(transformedData);
  }, [transformedData]);

  const {
    isLoading: isLoadingAllRPMEligiblePatient,
    refetch: refreshAllRPMEligiblePatient,
    data: allRPMEligiblePatient,
  } = useQuery('get-all-rpm-eligible-provider-patient', () =>
    apiClient.getallRPMEligibleProviderPatient(
      (userData?.userOrg as number).toString(),
      selectStartDate
        ? moment(selectStartDate.toDate()).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      selectEndDate && moment(selectEndDate.toDate()).format('YYYY-MM-DD'),
    ),
  );

  const extractProviderNames = (data: any): string[] => {
    if (data && typeof data === 'object' && !Array.isArray(data)) {
      return Object.keys(data);
    }
    return [];
  };

  const transformData = (data: any) => {
    if (data && typeof data === 'object' && !Array.isArray(data)) {
      return Object.keys(data).flatMap(key => data[key]);
    }
    return [];
  };

  const filterByProvider = (data: any, providerName: string) => {
    if (providerName === 'All Providers') {
      return transformData(data);
    }
    return providerName ? data[providerName] || [] : [];
  };

  useEffect(() => {
    if (allRPMEligiblePatient) {
      const providers = extractProviderNames(allRPMEligiblePatient);
      setProviderNames(providers);
      setTransformedData(transformData(allRPMEligiblePatient));

      if (!selectedProvider) {
        setSelectedProvider('All Providers');
      }
    }
  }, [allRPMEligiblePatient]);

  useEffect(() => {
    filterProviderData();
  }, [selectedProvider, transformedData]);

  const filterProviderData = () => {
    if (allRPMEligiblePatient && selectedProvider) {
      let filteredData = filterByProvider(
        allRPMEligiblePatient,
        selectedProvider,
      );

      // Sort the filtered data by `scheduled_at_date` in ascending order
      filteredData = filteredData.sort((a: any, b: any) => {
        const dateA = moment(a.scheduled_at_date);
        const dateB = moment(b.scheduled_at_date);

        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
      });

      setFilteredList(Array.isArray(filteredData) ? filteredData : []);
    } else {
      setFilteredList([]);
    }
  };

  useEffect(() => {
    refreshAllRPMEligiblePatient();
  }, [selectStartDate, selectEndDate]);

  return (
    <div style={{ width: '100%', padding: '10px', paddingTop: 0 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 18,
          fontWeight: 'bold',
          width: '100%',
        }}
      >
        <div
          style={{
            width: 280,
            color: '#ffff',
            backgroundColor: '#90CAF9',
            paddingTop: 10,
            paddingBottom: 20,
            padding: 10,
            textAlign: 'center',
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            boxShadow: '2px 2px solid gray',
          }}
        >
          RPM ELIGIBLE PATIENT LIST
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 20,
          marginTop: 40,
        }}
      >
        <div style={{ display: 'flex', gap: 20 }}>
          <FormControl sx={{ minWidth: 180 }} size="small">
            <InputLabel
              sx={{
                '& .MuiInputBase-root': {
                  height: '20px',
                },
              }}
            >
              Select Physician
            </InputLabel>
            <Select
              value={selectedProvider}
              label="Select Physician"
              onChange={event => setSelectedProvider(event.target.value)}
            >
              <MenuItem value="All Providers">All Providers</MenuItem>{' '}
              {providerNames?.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: 'Check-in', end: 'Check-out' }}
          >
            <DatePicker
              label="Start Date"
              value={!selectStartDate ? moment() : selectStartDate}
              onChange={(newValue: Dayjs | null) => {
                setSelectStartDate(newValue);
              }}
              minDate={dayjs().subtract(15, 'days')}
              maxDate={dayjs().add(15, 'days')}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '40px',
                    },
                    '& .MuiFormLabel-root': {
                      lineHeight: '8px',
                      height: '40px',
                    },
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={selectEndDate}
              onChange={(newValue: Dayjs | null) => {
                setSelectEndDate(newValue);
              }}
              minDate={dayjs().subtract(15, 'days')}
              maxDate={dayjs().add(15, 'days')}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '40px',
                    },
                    '& .MuiFormLabel-root': {
                      lineHeight: '10px',
                      height: '40px',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 10,
            }}
          >
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  style={{ padding: '8px 12px', background: '#90CAF9' }}
                >
                  Print Today's Report
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
      <div>
        {isLoadingAllRPMEligiblePatient ? (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <table
            id="rpmEligibleData"
            style={{
              position: 'sticky',
              zIndex: 10,
              userSelect: 'none',
              cursor: 'default',
              opacity: false ? 0.25 : 1,
              marginTop: 20,
            }}
            ref={componentRef}
          >
            <thead style={{ color: 'black', position: 'sticky', top: 0 }}>
              <tr>
                <th style={{ borderTopLeftRadius: '5px' }}>
                  <b>Provider</b>
                </th>
                <th>
                  <b>Patient</b>
                </th>
                <th>
                  <b>Appointment</b>
                </th>
                <th>
                  <b>Insurance</b>
                </th>
                <th>
                  <b>Location</b>
                </th>
                <th style={{ width: '280px', borderTopRightRadius: '5px' }}>
                  <b>Diagnostic Group</b>
                </th>
              </tr>
            </thead>

            <thead style={{ color: 'black', top: 0 }}>
              {filteredList && filteredList?.length > 0 ? (
                filteredList?.map((item: any) => (
                  <tr key={item?.id} className="table-data">
                    <td>{item?.provider_name}</td>
                    <td>{item?.patient_name}</td>
                    <td>
                      {item?.scheduled_at_date &&
                        moment(item?.scheduled_at_date).format(
                          'MMM DD, YYYY',
                        )}{' '}
                      {item?.scheduled_at_time}
                    </td>
                    <td>{item?.insurance_name}</td>
                    <td>{item?.location}</td>
                    <td style={{ width: '280px' }}>{item?.group}</td>
                  </tr>
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>No matching records found.</p>
                </div>
              )}
            </thead>
          </table>
        )}
      </div>
    </div>
  );
};

export default RPMEligiblePatient;
