import React from 'react';
import {
  GetUserListResponse,
  NetworkResponse,
  UpdateAlertStatusRequest,
  UserDataResponse,
} from 'api/SharedTypes';
import './NetworkInfo.css';
import { CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import { UserRole } from 'services/Utils';
import I18nKey from 'lib/I18nKeys';
import PatientNetwork from './PatientNetwork';
import ProviderNetwork from './ProviderNetwork.tsx';

interface NetworkInfoProps {
  readonly userData?: UserDataResponse;
  readonly isLoading: boolean;
  readonly networkData?: NetworkResponse[];
  readonly role: UserRole;
  readonly handleNetworkMemberAlertStatusUpdate?: (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => void;
  readonly refreshNetworkList?: (id: number, role: UserRole) => void;
  readonly patientNetwork?: GetUserListResponse[];
  readonly patientNetworkRefresh?: (id: number) => void;
}

export const NetworkInfo: React.FC<NetworkInfoProps> = ({
  userData,
  networkData,
  role,
  isLoading,
  handleNetworkMemberAlertStatusUpdate,
  refreshNetworkList,
  patientNetwork,
  patientNetworkRefresh,
}) => {
  const intl = useIntl();

  return (
    <>
      <div className="networkInfoTitleText">
        {intl.formatMessage({ id: I18nKey.NETWORK_INFO_MEMBERS_TITLE })}
      </div>
      {isLoading ? (
        <div className="centerLoading">
          <CircularProgress />
        </div>
      ) : role === UserRole.patient ? (
        <PatientNetwork
          selectedUserData={userData!}
          handleNetworkMemberAlertStatusUpdate={
            handleNetworkMemberAlertStatusUpdate
          }
          patientNetworkList={patientNetwork}
          patientNetworkRefresh={patientNetworkRefresh}
        />
      ) : (
        <ProviderNetwork
          userData={userData!}
          networkData={networkData!}
          refreshNetworkList={refreshNetworkList}
        />
      )}
    </>
  );
};
